import React from 'react'
import { Link } from 'gatsby'

const StraitMenu = (props) => (
  <div>
  <h2 className="major">George Strait</h2>
  <ul className="bigList">
  <li className={props.pageName === '2015_tour' ? 'current' : ''}><Link to="/clients/georgestrait/2015_tour">2015 "The Cowboy Rides Away" Tour</Link></li>
  </ul>
  </div>
)

export default StraitMenu
