import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import NakaLightbox from '../../../components/NakaLightbox'
import Layout from '../../../components/layout'
import StraitMenu from './_menu.js'

const Strait2015TourPage = ({ data }) => (
  <Layout bgClass="clients-strait">
    <StraitMenu pageName="2015_tour" />
    <div className="textPageDetails">
      <p>
        Nakatomi produced posters for all 25 dates of George Strait's 2015 "The Cowboy Rides Away" retirement tour. Instead of a unique print per show, the tour used one image for 24 dates, with different date/ venue information, and a new illustration for the final date.
      </p>
    </div>
    <div className="gallery-container straitPage">
    <NakaLightbox images={data.allFile.edges} delimiter=" -- " />
    </div>
  </Layout>
)

Strait2015TourPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Strait2015TourPage

export const pageQuery = graphql`
  query Strait2015TourPageIndexQuery {
    allFile(
      filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativePath: {regex: "/georgestrait/2015_tour/"}}
    	sort: {fields: name}
    ) {
      totalCount
      edges {
        node {
          childImageSharp {
            id
            sizes(maxWidth: 1800) {
              ...GatsbyImageSharpSizes
            }
            fluid {
              srcSet
              src
              originalName
              presentationWidth
              presentationHeight
              originalImg
            }
          }
        }
      }
    }
  }
`
